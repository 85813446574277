import { StaticQuery } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Post from "../components/Post"
import PaginationLinks from "../components/PaginationLinks"

import { Container, Row, Col } from "react-bootstrap"
import Sidebar from "../components/Sidebar"

const IndexPage = () => {
  return (
    <Layout pageTitle="Interior Design">
      <SEO>
      title="Interior Design Solutions"
      </SEO>
      {/* <SEO/> */}
      
      <Row>
        <Col>
        <center><b><h1>Interior Design Solutions</h1></b></center>
        </Col>
        
      </Row>
      <Row>
        <Col md="9">
          <Introduction />
        </Col>
        <Col md="3">
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.4)",
            }}
          >
            <Sidebar />
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

function Introduction() {
  const postsPerPage = 10
  let numberOfPages
  return (
    <StaticQuery
      query={indexQuery}
      render={data => {
        numberOfPages = Math.ceil(
          data.allMarkdownRemark.totalCount / postsPerPage
        )
        return (
          <div>
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <Post
                key={node.id}
                title={node.frontmatter.title}
                author={node.frontmatter.author}
                slug={node.fields.slug}
                rank= {node.frontmatter.rank}
                body={node.excerpt}
                fluid={node.frontmatter.image.childImageSharp.fluid}
                tags={node.frontmatter.tags}
              />
            ))}
            <PaginationLinks currentPage={1} numberOfPages={numberOfPages} />
          </div>
        )
      }}
    />
  )
}
const indexQuery = graphql`
  query {
    allMarkdownRemark(limit: 10) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date
            author
            tags
            rank
            image {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
export default IndexPage
